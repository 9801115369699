<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            label="요청부서"
            :isFirstValue="false"
            name="requestDeptCd"
            v-model="searchParam.requestDeptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            label="검토부서"
            name="reviewDeptCd"
            :isFirstValue="false"
            v-model="searchParam.reviewDeptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="인허가 요청 및 검토"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn label="인허가 요청" v-if="editable" icon="add" @btnClicked="linkClick" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "license-request",
  data() {
    return {
      searchParam: {
        plantCd: null,
        requestDeptCd: null,
        reviewDeptCd: null,
        licenseRequestName: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'limLicenseRequestId' },
          { index: 1, colName: 'limLicenseRequestId' },
          { index: 7, colName: 'limLicenseRequestId' },
          { index: 8, colName: 'limLicenseRequestId' },
          { index: 9, colName: 'limLicenseRequestId' },
          { index: 10, colName: 'limLicenseRequestId' },
          { index: 11, colName: 'limLicenseRequestId' },
          { index: 12, colName: 'limLicenseRequestId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'licenseRequestName',
            field: 'licenseRequestName',
            label: '인허가 요청명',
            align: 'left',
            type: 'link',
            style: 'width:240px',
            sortable: true,
          },
          {
            label: '인허가종류별 검토현황',
            align: 'center',
            child: [
              {
                name: 'limLicenseKindStatusName',
                field: 'limLicenseKindStatusName',
                label: '검토상태',
                align: 'left',
                style: 'width:160px',
                sortable: false,
              },
              {
                name: 'relatedLawsName',
                field: 'relatedLawsName',
                label: '관련법규',
                align: 'left',
                style: 'width:200px',
                sortable: false,
              },
              {
                name: 'limLicenseKindName',
                field: 'limLicenseKindName',
                label: '인허가종류',
                align: 'left',
                style: 'width:240px',
                sortable: false,
              },
              {
                name: 'reviewDeptName2',
                field: 'reviewDeptName2',
                label: '검토부서',
                align: 'center',
                style: 'width:100px',
                sortable: false,
              },
              {
                name: 'reviewUserName2',
                field: 'reviewUserName2',
                label: '검토부서',
                align: 'center',
                style: 'width:100px',
                sortable: false,
              },
            ]
          },
          {
            name: 'completeRequestDate',
            field: 'completeRequestDate',
            label: '완료 요청일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'requestStepName',
            field: 'requestStepName',
            label: '전체 진행 단계',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'requestDeptName',
            field: 'requestDeptName',
            label: '인허가요청부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'requestUserName',
            field: 'requestUserName',
            label: '인허가요청자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'reviewDeptName',
            field: 'reviewDeptName',
            label: '인허가검토부서',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            label: '인허가검토자',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.lim.lincense.request.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = '인허가 요청 및 검토';
      this.popupOptions.param = {
        stepCd: row ? row.requestStepCd : '',
        limLicenseRequestId: row ? row.limLicenseRequestId : '',
      };
      this.popupOptions.target = () => import(`${"./licenseRequestStep.vue"}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
